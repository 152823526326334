import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import JudgeLive from "./liveJudge.js";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      students: {},
      studentsKeys: [],
      name: "",
      desc: "",
      value: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .database()
      .ref("peshfair/entries")
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState(
            {
              students: snapshot.val(),
              studentsKeys: Object.keys(snapshot.val()),
            },
            this.rankProjects
          );
          console.log({
            students: snapshot.val(),
            studentsKeys: Object.keys(snapshot.val()),
          });
        } else {
          this.setState({ students: {}, studentsKeys: [] });
        }
      });
    // firebase
    // .database()
    // .ref("students/" + firebase.auth().currentUser.uid)
    // .set([{
    //     name: "TESTING",
    //     desc: "Hello",
    //     value: 3
    // }]);
  };  

  renderLifeSciences = () => {
    return (
      <>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Research Question (10 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clear and focused purpose
            </label>
            <select
              required
              id="purpose"
              name="purpose"
              value={this.state.purpose}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Identified contribution to field of study
            </label>
            <select
              required
              id="contribution"
              name="contribution"
              value={this.state.contribution}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Testable using scientific methods
            </label>
            <select
              required
              id="method"
              name="method"
              value={this.state.method}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Design and Methodology (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Well-designed plan and data collection methods
            </label>
            <select
              required
              id="datacollection"
              name="datacollection"
              value={this.state.datacollection}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Variables and controls defined, appropriate, and complete
            </label>
            <select
              required
              id="variables"
              name="variables"
              value={this.state.variables}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Execution: Data Collection, Analysis, and Interpretation (20
              Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Systematic data collection and analysis
            </label>
            <select
              required
              id="systematic"
              name="systematic"
              value={this.state.systematic}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Reproducibility of results
            </label>
            <select
              required
              id="reproducibility"
              name="reproducibility"
              value={this.state.reproducibility}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Appropriate application of mathematical and statistical methods
            </label>
            <select
              required
              id="math"
              name="math"
              value={this.state.math}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Sufficient data collected to support interpretation and
              conclusions
            </label>
            <select
              required
              id="collected"
              name="collected"
              value={this.state.collected}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Creativity (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Project demonstrates creativity in one or more of the above
              criteria
            </label>
            <select
              required
              id="creativity"
              name="creativity"
              value={this.state.creativity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Presentation (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Logical organization of material
            </label>
            <select
              required
              id="logical"
              name="logical"
              value={this.state.logical}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clarity of graphics and legends
            </label>
            <select
              required
              id="clarity"
              name="clarity"
              value={this.state.clarity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Supporting documentation displayed
            </label>
            <select
              required
              id="documentation"
              name="documentation"
              value={this.state.documentation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Interview (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding of basic science relevant to project
            </label>
            <select
              required
              id="science"
              name="science"
              value={this.state.science}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding interpretation & limitations of results &
              conclusions
            </label>
            <select
              required
              id="interpretation"
              name="interpretation"
              value={this.state.interpretation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Degree of independence in conducting project
            </label>
            <select
              required
              id="independence"
              name="independence"
              value={this.state.independence}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Recognition of potential impact in science, society and/or
              economics
            </label>
            <select
              required
              id="impact"
              name="impact"
              value={this.state.impact}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Quality of ideas for further research
            </label>
            <select
              required
              id="quality"
              name="quality"
              value={this.state.quality}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Judge Comments
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Comments will be sent to students post-fair.
            </label>
            <textarea
              required
              id="comments"
              name="comments"
              value={this.state.comments}
              onChange={this.handleChanges}
              class="mt-1 block w-full h-40 py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            ></textarea>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
      </>
    );
  };

  renderEngineering = () => {
    return (
      <>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Research Problem (10 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Description of a Practical Need or Problem to Be Solved
            </label>
            <select
              required
              id="purpose"
              name="purpose"
              value={this.state.purpose}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Definition of Criteria for Proposed Solution
            </label>
            <select
              required
              id="criteria"
              name="criteria"
              value={this.state.criteria}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Explanation of Constraints
            </label>
            <select
              required
              id="constraints"
              name="constraints"
              value={this.state.constraints}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Design and Methodology (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Exploration of Alternatives to Answer Need or Problem
            </label>
            <select
              required
              id="alternatives"
              name="alternatives"
              value={this.state.alternatives}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Identification of a Solution
            </label>
            <select
              required
              id="identification"
              name="identification"
              value={this.state.identification}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Development of a Prototype/Model
            </label>
            <select
              required
              id="prototype"
              name="prototype"
              value={this.state.prototype}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Execution: Data Collection, Analysis, and Interpretation (20
              Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype Demonstrates Intended Design
            </label>
            <select
              required
              id="intended"
              name="intended"
              value={this.state.intended}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype has Been Tested in Multiple Condition/Trials
            </label>
            <select
              required
              id="reproducibility"
              name="reproducibility"
              value={this.state.reproducibility}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype Demonstrates Engineering Skill and Completeness
            </label>
            <select
              required
              id="skill"
              name="skill"
              value={this.state.skill}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Creativity (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Project demonstrates creativity in one or more of the above
              criteria
            </label>
            <select
              required
              id="creativity"
              name="creativity"
              value={this.state.creativity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Presentation (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Logical Organization of Material
            </label>
            <select
              required
              id="logical"
              name="logical"
              value={this.state.logical}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clarity of Graphics and Legends
            </label>
            <select
              required
              id="clarity"
              name="clarity"
              value={this.state.clarity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Supporting Documentation Displayed
            </label>
            <select
              required
              id="documentation"
              name="documentation"
              value={this.state.documentation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Interview (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clear, Concise, and Thoughtful Responses to Questions
            </label>
            <select
              required
              id="responses"
              name="responses"
              value={this.state.responses}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding of Basic Science Relevant to Project
            </label>
            <select
              required
              id="science"
              name="science"
              value={this.state.science}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding Interpretation and Limitations of Results and
              Conclusions
            </label>
            <select
              required
              id="interpretation"
              name="interpretation"
              value={this.state.interpretation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Degree of Independence in Conducting Project
            </label>
            <select
              required
              id="independence"
              name="independence"
              value={this.state.independence}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Recognition of potential impact in science, society and/or
              economics
            </label>
            <select
              required
              id="impact"
              name="impact"
              value={this.state.impact}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Quality of ideas for further research
            </label>
            <select
              required
              id="quality"
              name="quality"
              value={this.state.quality}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Judge Comments
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Comments will be sent to students post-fair.
            </label>
            <textarea
              required
              id="comments"
              name="comments"
              value={this.state.comments}
              onChange={this.handleChanges}
              class="mt-1 block w-full h-40 py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            ></textarea>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
      </>
    );
  };

  renderEdit = () => {
    return (
      <form onSubmit={this.handleSubmit}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-scroll">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div
            class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-scroll shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            style={{ maxHeight: "48rem" }}
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {this.state.rubric == "exp"
              ? this.renderLifeSciences()
              : this.renderEngineering()}
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({
                      modal: false,
                    })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  startEdit = (judge, value) => {
    console.log(judge, value);
    this.setState({
      modal: true,
      student: value,
      ...this.state.students[value].rubric[judge],
    });
  };

  getCumulative = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      sum += this.calcSum(obj[arr[i]]);
    }
    return sum / arr.length;
  };

  calcSum = (obj) => {
    if (obj.rubric == "exp") {
      return (
        parseInt(obj.purpose) +
        parseInt(obj.contribution) +
        parseInt(obj.method) +
        parseInt(obj.datacollection) +
        parseInt(obj.variables) +
        parseInt(obj.systematic) +
        parseInt(obj.reproducibility) +
        parseInt(obj.math) +
        parseInt(obj.collected) +
        parseInt(obj.creativity) +
        parseInt(obj.logical) +
        parseInt(obj.clarity) +
        parseInt(obj.documentation) +
        parseInt(obj.science) +
        parseInt(obj.interpretation) +
        parseInt(obj.independence) +
        parseInt(obj.impact) +
        parseInt(obj.quality)
      );
    } else if (obj.rubric == "eng") {
      return (
        parseInt(obj.purpose) +
        parseInt(obj.criteria) +
        parseInt(obj.constraints) +
        parseInt(obj.alternatives) +
        parseInt(obj.identification) +
        parseInt(obj.prototype) +
        parseInt(obj.intended) +
        parseInt(obj.reproducibility) +
        parseInt(obj.skill) +
        parseInt(obj.creativity) +
        parseInt(obj.logical) +
        parseInt(obj.clarity) +
        parseInt(obj.documentation) +
        parseInt(obj.science) +
        parseInt(obj.interpretation) +
        parseInt(obj.independence) +
        parseInt(obj.impact) +
        parseInt(obj.quality)
      );
    } else {
      alert("error with rubric.");
    }
  };

  renderJudges = (obj, value) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      let judge = arr[i];
      toReturn.push(
        <p
          class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
          onClick={() => this.startEdit(judge, value)}
        >
          {obj[arr[i]].name + " - " + this.calcSum(obj[arr[i]])}
        </p>
      );
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  rankProjects = () => {
    var values = {};
    var valuesArray = [];
    for (var o = 0; o < this.state.studentsKeys.length; o++) {
      console.log(o);
      if (
        this.state.students[this.state.studentsKeys[o]].entry != null ||
        this.state.students[this.state.studentsKeys[o]].rubric == null ||
        this.state.students[this.state.studentsKeys[o]].category !=
          this.props.category
      ) {
      } else {
        var arr = Object.keys(
          this.state.students[this.state.studentsKeys[o]].rubric
        );
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
          console.log(arr[i]);
          sum += this.calcSum(
            this.state.students[this.state.studentsKeys[o]].rubric[arr[i]]
          );
        }
        values[this.state.studentsKeys[o]] = sum / arr.length;

        console.log(values);
      }
    }
    const leaderboard = Object.assign(
      {},
      ...Object.entries(values)
        .sort(([, a], [, b]) => b - a)
        .map(([p], i) => ({ [i + 1]: p }))
    );

    console.log(leaderboard);
    console.log(Object.values(leaderboard));

    this.setState({ rankedKeys: Object.values(leaderboard) });
    this.setState({ ranks: Object.keys(leaderboard) });

    // this.state.studentsKeys.map((value, index) => {
    //   if (this.state.students[value].entry != null) return null;
    //   var arr = Object.keys(this.state.students[value].rubric);
    //   var sum = 0;
    //   for (var i = 0; i < arr.length; i++) {
    //     console.log(arr[i]);
    //     sum += this.state.students[value].rubric[arr[i]].value;
    //   }
    //   values[value] = (sum / arr.length);

    //   console.log(values);
    // })
  };

  renderStudents = (arr) => {
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      // toReturn.push(<p>{arr[i]}</p>);
      toReturn.push(<p>Replaced Name</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  renderFiles = (obj) => {
    return (
      <>
        {Object.keys(obj).map((value, index) => {
          return (
            <p class="text-left">
              <a
                href={
                  "https://peshfair2020.usere.repl.co/media/" + value + ".pdf"
                }
                class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                target="_blank"
              >
                {obj[value]}
              </a>
            </p>
          );
        })}
      </>
    );
  };

  renderTable = () => {
    if (this.state.rankedKeys.length > 0) {
      return (
        <>
          {this.state.rankedKeys.map((value, index) => {
            if (this.state.students[value].entry != null) return null;
            if (this.state.students[value].category != this.props.category)
              return null;
            return (
              <tr>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.ranks[index]}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].title}
                  <br />
                  <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs leading-4 font-medium bg-blue-100 rounded-full">
                    {this.state.students[value].category}
                  </span>
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.renderStudents(this.state.students[value].students)}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].files
                    ? this.renderFiles(this.state.students[value].files)
                    : null}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    {this.renderJudges(
                      this.state.students[value].rubric,
                      value
                    )}
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    {this.state.students[value].rubric
                      ? this.getCumulative(this.state.students[value].rubric)
                      : "Not Yet Judged"}
                  </a>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  renderBelow = () => {
    if (this.state.studentsKeys.length > 0) {
      return (
        <>
          {this.state.studentsKeys.map((value, index) => {
            if (this.state.students[value].entry != null) return null;
            if (this.state.students[value].category != this.props.category)
              return null;
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.students[value].title}
                  <br />
                  <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs leading-4 font-medium bg-blue-100 rounded-lg">
                    {this.state.students[value].category}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {!this.state.students[value].live ? (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                      Free for Judging
                    </span>
                  ) : (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-red-300 rounded-full">
                      {"Live with " + this.state.students[value].activeJudge}
                    </span>
                  )}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.renderStudents(this.state.students[value].students)}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].files
                    ? this.renderFiles(this.state.students[value].files)
                    : null}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    {this.state.students[value].rubric
                      ? this.renderJudges(
                          this.state.students[value].rubric,
                          value
                        )
                      : "Not Yet Judged"}
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    {this.state.students[value].rubric
                      ? this.getCumulative(this.state.students[value].rubric)
                      : "Not Yet Judged"}
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <a
                    href="#"
                    onClick={() => alert("Need to fix")}
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {"Edit"}
                  </a>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  renderModal = () => {
    return (
      <form onSubmit={this.handleSubmitModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </div>
      </form>
    );
  };

  joinJudge = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeetingJudge?" +
            "fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  startJudge = (value) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeeting?tojoin=" +
            value +
            "&fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
    // this.setState({
    //     live: false,
    //     joinUrl: "https://demobbb.fairkit.io/bigbluebutton/api/join?fullName=attendee&meetingID=1&password=secret&checksum=4def2aca8ad74af14da83593ac67886eab441480"
    // });
  };

  render() {
    if (!!this.state.live) {
      return (
        <JudgeLive joinUrl={this.state.joinUrl} student={this.state.student} />
      );
    } else {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Ranking: {this.props.category}
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                {/* <div class="bg-gray-50 shadow sm:rounded-lg mt-4 mb-4">
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Role: Judge
                    </h3>
                    <div class="mt-2">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => this.joinJudge()}
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Join Judge Breakouts
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Advance Students
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Send Judge Notifications
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 sm:mt-6 mb-2">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div> */}

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Rank #
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Project
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Students
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Files
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Rubrics
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                  </div>
                </div>
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <h2 className="text-lg font-semibold text-gray-900 ml-2 mb-2">
                    All Projects
                  </h2>
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Project
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Students
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Files
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Rubrics
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Score
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderBelow()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}
