import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import JudgeLive from "./liveJudge.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      students: {},
      studentsKeys: [],
      name: "",
      desc: "",
      value: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("https://api.peshnhs.org/getData", {
          headers: {
            idtoken: idToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({
                students: data.students,
                studentsKeys: data.array,
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.5" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.6" + error
        );
      });
  };

  getCumulative = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      sum += this.calcSum(obj[arr[i]]);
    }
    return sum / arr.length;
  };

  renderJudges = (obj, value) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      let judge = arr[i];
      toReturn.push(
        <p
          class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
          onClick={() => this.startEdit(judge, value)}
        >
          {obj[arr[i]].name + " - " + this.calcSum(obj[arr[i]])}
        </p>
      );
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  calcSum = (obj) => {
    if (obj.rubric == "exp") {
      return (
        parseInt(obj.purpose) +
        parseInt(obj.contribution) +
        parseInt(obj.method) +
        parseInt(obj.datacollection) +
        parseInt(obj.variables) +
        parseInt(obj.systematic) +
        parseInt(obj.reproducibility) +
        parseInt(obj.math) +
        parseInt(obj.collected) +
        parseInt(obj.creativity) +
        parseInt(obj.logical) +
        parseInt(obj.clarity) +
        parseInt(obj.documentation) +
        parseInt(obj.science) +
        parseInt(obj.interpretation) +
        parseInt(obj.independence) +
        parseInt(obj.impact) +
        parseInt(obj.quality)
      );
    } else if (obj.rubric == "eng") {
      return (
        parseInt(obj.purpose) +
        parseInt(obj.criteria) +
        parseInt(obj.constraints) +
        parseInt(obj.alternatives) +
        parseInt(obj.identification) +
        parseInt(obj.prototype) +
        parseInt(obj.intended) +
        parseInt(obj.reproducibility) +
        parseInt(obj.skill) +
        parseInt(obj.creativity) +
        parseInt(obj.logical) +
        parseInt(obj.clarity) +
        parseInt(obj.documentation) +
        parseInt(obj.science) +
        parseInt(obj.interpretation) +
        parseInt(obj.independence) +
        parseInt(obj.impact) +
        parseInt(obj.quality)
      );
    } else {
      alert("error with rubric.");
    }
  };

  rankProjects = () => {
    var values = {};
    var valuesArray = [];
    for (var o = 0; o < this.state.studentsKeys.length; o++) {
      console.log(o);
      if (
        this.state.students[this.state.studentsKeys[o]].entry != null ||
        this.state.students[this.state.studentsKeys[o]].rubric == null
      ) {
      } else {
        var arr = Object.keys(
          this.state.students[this.state.studentsKeys[o]].rubric
        );
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
          console.log(arr[i]);
          sum += this.calcSum(
            this.state.students[this.state.studentsKeys[o]].rubric[arr[i]]
          );
        }
        values[this.state.studentsKeys[o]] = sum / arr.length;

        console.log(values);
      }
    }
    const leaderboard = Object.assign(
      {},
      ...Object.entries(values)
        .sort(([, a], [, b]) => b - a)
        .map(([p], i) => ({ [i + 1]: p }))
    );

    console.log(leaderboard);
    console.log(Object.values(leaderboard));

    this.setState({ rankedKeys: Object.values(leaderboard) });
    this.setState({ ranks: Object.keys(leaderboard) });

    // this.state.studentsKeys.map((value, index) => {
    //   if (this.state.students[value].entry != null) return null;
    //   var arr = Object.keys(this.state.students[value].rubric);
    //   var sum = 0;
    //   for (var i = 0; i < arr.length; i++) {
    //     console.log(arr[i]);
    //     sum += this.state.students[value].rubric[arr[i]].value;
    //   }
    //   values[value] = (sum / arr.length);

    //   console.log(values);
    // })
  };

  renderStudents = (arr) => {
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      // toReturn.push(<p>{arr[i]}</p>);
      toReturn.push(<p>Replaced Name</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  renderLive = () => {
    if (this.state.studentsKeys.length > 0) {
      return (
        <>
          {this.state.studentsKeys.map((value, index) => {
            if (this.state.students[value].entry != null) return null;
            if (!this.state.students[value].live) return null;
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.students[value].firstname +
                    this.state.students[value].lastname}
                  <br />
                  <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs leading-4 font-medium bg-blue-100 rounded-full">
                    {this.state.students[value].idnumber}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {!this.state.students[value].live ? (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                      Free for Judging
                    </span>
                  ) : (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-red-300 rounded-full">
                      {"Live with " + this.state.students[value].activeJudge}
                    </span>
                  )}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {"test"}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  test
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    test
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    test
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <a
                    href={this.state.students[value].joinUrl}
                    target="_blank"
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {"Join"}
                  </a>
                </td>
              </tr>
            );
          })}

          <tr>
            <span class="mx-6 my-24 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Live projects will populate above.
            </span>
          </tr>
        </>
      );
    }
  };

  renderFiles = (obj) => {
    return (
      <>
        {Object.keys(obj).map((value, index) => {
          return (
            <p class="text-left">
              <a
                href={
                  "https://peshfair2020.usere.repl.co/media/" + value + ".pdf"
                }
                class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                target="_blank"
              >
                {obj[value]}
              </a>
            </p>
          );
        })}
      </>
    );
  };

  renderTable = () => {
    if (this.state.studentsKeys.length > 0) {
      return (
        <>
          {this.state.studentsKeys.map((value, index) => {
            return (
              <tr
                class={
                  this.state.students[value].submitted ? null : "bg-yellow-100"
                }
              >
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.students[value].firstname}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].lastname}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].idnumber}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].tshirt}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].gradelevel}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].pisd}
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <a
                    href={"mailto:" + this.state.students[value].emailaddress}
                    class="focus:outline-none"
                  >
                    Email
                  </a>
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                Logs:
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <NavLink
                    to={{
                      pathname: "/creditFall/" + value,
                      aboutProps: {
                        name:
                          this.state.students[value].firstname +
                          this.state.students[value].lastName,
                      },
                    }}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Fall
                  </NavLink>
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <NavLink
                    to={{
                      pathname: "/creditSpring/" + value,
                      aboutProps: {
                        name:
                          this.state.students[value].firstname +
                          this.state.students[value].lastName,
                      },
                    }}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Spring
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  renderModal = () => {
    return (
      <form onSubmit={this.handleSubmitModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Send Judge Notifications
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Send notifications to all judges in your category.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="about"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to all judges in "Category: Chemistry".
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="desc"
                      name="fjlad"
                      required
                      rows="3"
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => this.setState({ modal: false })}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderEdit = () => {
    return (
      <form onSubmit={this.handleSubmit}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-scroll">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div
            class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-scroll shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            style={{ maxHeight: "48rem" }}
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {this.state.rubric == "exp"
              ? this.renderLifeSciences()
              : this.renderEngineering()}
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({
                      modal: false,
                    })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  startEdit = (judge, value) => {
    console.log(judge, value);
    this.setState({
      modal: true,
      student: value,
      ...this.state.students[value].rubric[judge],
    });
  };

  joinJudge = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeetingJudge?" +
            "fair=" +
            "peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.1");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.2");
      });
  };

  startJudge = (value) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeeting?tojoin=" +
            value +
            "&fair=" +
            "peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.3");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.4");
      });
    // this.setState({
    //     live: false,
    //     joinUrl: "https://demobbb.fairkit.io/bigbluebutton/api/join?fullName=attendee&meetingID=1&password=secret&checksum=4def2aca8ad74af14da83593ac67886eab441480"
    // });
  };

  render() {
    if (!!this.state.live) {
      return (
        <JudgeLive joinUrl={this.state.joinUrl} student={this.state.student} />
      );
    } else {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Student Viewer
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Add Student Credits
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Click the "Edit" indicator to open the student's
                          Credit Log.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                {/* <div class="bg-gray-50 shadow sm:rounded-lg mt-4 mb-4">
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Role: Judge
                    </h3>
                    <div class="mt-2">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => this.joinJudge()}
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Join Judge Breakouts
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Advance Students
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Send Judge Notifications
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 sm:mt-6 mb-2">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div> */}

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <h2 className="text-lg font-semibold text-gray-900 ml-2 mb-2">
                    All Students
                  </h2>
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            First Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Last Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            T-Shirt
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Grade
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            PISD
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}
