import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import { withRouter } from "react-router";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: "",
      slug: "",
      desc: "",
      credits: 0,
      codes: {},
      attendees: {},
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    firebase
      .database()
      .ref("events/" + this.props.match.params.id)
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          const object = { ...snapshot.val() };
          this.setState({
            ...object,
          });
        } else {
          this.setState({ name: 0 });
        }
      });
  };

  startEdit = (index) => {
    this.setState({
      editing: true,
      editInd: index,
      name: this.state.credits[index].name,
      value: this.state.credits[index].value,
      desc: this.state.credits[index].desc,
      file: this.state.credits[index].file,
    });
  };

  startModal = (index) => {
    this.setState({
      modal: true,
      name: "",
      value: "",
      desc: "",
      file: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.slug == "") {
      alert("Please add a slug.");
    } else {
      firebase
        .database()
        .ref("events/" + this.props.match.params.id)
        .update(this.state)
        .then(() => {
          alert("Saved!");
        });
    }
  };

  handleEditModal = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .set({
        name: this.state.name,
        value: this.state.value,
        desc: this.state.desc,
        file: this.state.file,
      })
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleDeleteModal = () => {
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .remove()
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  renderOptions = () => {
    return (
      <>
        {Object.keys(this.state.script).map((value, index) => {
          return (
            <>
              <option value={value}>{value}</option>
            </>
          );
        })}
      </>
    );
  };

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {/* {this.state.actionModal && this.renderModal()} */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <NavLink
            to="/events/"
            exact
            className="hidden text-gray-900 hover:text-gray-700 md:block"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </NavLink>
          <h1 className="text-2xl font-semibold text-gray-900">
            Configure {this.state.name}
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <form onSubmit={this.handleSubmit}>
            <div className="py-4">
              <div>
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Submission Site
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Be sure to review the Submission Instructions before
                          uploading. Submission DEADLINE: April 9th.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Descriptors
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Descriptive details of the phone bank
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Name
                          </label>
                          <input
                            required
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Slug
                          </label>
                          <input
                            required
                            id="slug"
                            name="slug"
                            value={this.state.slug}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Description
                          </label>
                          <input
                            required
                            id="desc"
                            name="desc"
                            value={this.state.desc}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Credits
                          </label>
                          <input
                            id="credits"
                            required
                            name="credits"
                            value={this.state.credits}
                            type="number"
                            step="0.5"
                            min="0"
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-between">
                      <div class="">
                        <span class="ml-3 inline-flex rounded-md shadow-sm">
                          <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Project Files
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please use this tool to add student uploads to your
                        project.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <label
                        class="text-center"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {this.showFiles()}
                        {this.SingleUpload(this.state.file)}
                      </label>
                    </div>
                  </div>
                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-end">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Save
                        </button>
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
export default withRouter(App);
