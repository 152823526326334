import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import Live from "./live";
import JudgeList from "./listJudge";
import HoldPage from "./holdPage";
import Home from "./home";
import Admin from "./admin";
import Registration from "./registration";
import Unauthorized from "./unauthorized";
import Profile from "./profile";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRoot = () => {
    if (this.props.role == 1) {
      return (
        <Admin email={this.props.email}/>
      );
    } else {
      return (
        <Route path="/">
          <Unauthorized />
        </Route>
      );
    }
  };

  renderSidebar = () => {
    if (this.props.role == 1) {
      return (
        <ul>
          <li>
            <NavLink
              title="Home"
              to="/"
              exact
              activeClassName="text-white bg-sidebar-active"
              className="h-16 px-6 flex items-center hover:text-white w-full"
            >
              <i className="mx-auto">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
              </i>
            </NavLink>
          </li>
          
          <li>
            <a
              onClick={() => firebase.auth().signOut()}
              href="#"
              className="h-16 px-6 flex items-center hover:text-white w-full"
            >
              <i className="mx-auto">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </i>
            </a>
          </li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            <NavLink
              title="Registration"
              to="/"
              exact
              activeClassName="text-white bg-sidebar-active"
              className="h-16 px-6 flex items-center hover:text-white w-full"
            >
              <i className="mx-auto">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </i>
            </NavLink>
          </li>
          <li>
            <a
              onClick={() => firebase.auth().signOut()}
              href="#"
              className="h-16 px-6 flex items-center hover:text-white w-full"
            >
              <i className="mx-auto">
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </i>
            </a>
          </li>
        </ul>
      );
    }
  };

  render() {
    return (
      <Router>
        <div className="h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white">
          <nav
            aria-label="side bar"
            aria-orientation="vertical"
            className="flex-none flex flex-col items-center text-center sidebar-bg text-gray-400 border-0"
          >
            {/* <div className="h-16 flex items-center w-full">
              <img
                className="h-6 w-6 mx-auto"
                src="https://raw.githubusercontent.com/bluebrown/tailwind-zendesk-clone/master/public/assets/leaves.png"
              />
            </div> */}
            {this.renderSidebar()}
          </nav>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <main style={{ height: "100vh" }}>{this.renderRoot()}</main>
          </div>
        </div>
      </Router>
    );
  }
}
