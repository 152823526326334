import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';


import SignIn from "./components/signin";
import Main from "./components/main";
import AccountDomain from "./components/accountDomain";

const firebaseConfig = {
  apiKey: "AIzaSyC3DOZIo3IjTJdDK7sutkg3Guz-dE_wS1g",
  authDomain: "peshnhs2025.firebaseapp.com",
  databaseURL: "https://peshnhs2025-default-rtdb.firebaseio.com",
  projectId: "peshnhs2025",
  storageBucket: "peshnhs2025.appspot.com",
  messagingSenderId: "658898231687",
  appId: "1:658898231687:web:0807cfaf9ce30fd5b0f322",
  measurementId: "G-B4XN4HQWLV"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: null,
      active: false,
      shouldRedirect: false,
    };
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onIdTokenChanged((user) => {
      if (!!user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            console.log(idTokenResult);
            console.log(idTokenResult.token);
            if (idTokenResult.claims.role != null) {
              this.setState({ isSignedIn: true, role: idTokenResult.claims.role, email: user.email});
            } else {
              this.setState({
                isSignedIn: true,
                role: 0,
                shouldRedirect: true,
                email: user.email
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({ isSignedIn: !!user });
      }
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
  //   return (<div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
  //       <div className="fixed inset-0 transition-opacity">
  //         <div className="absolute inset-0 bg-gray-500 opacity-75" />
  //       </div>
  //       <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
  //         <div className="sm:flex sm:items-start">
  //           <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
  //             <svg className="h-6 w-6 text-red-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
  //               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  //             </svg>
  //           </div>
  //           <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
  //             <h3 className="text-lg leading-6 font-medium text-gray-900">
  //               Demo Period Elapsed
  //             </h3>
  //             <div className="mt-2">
  //               <p className="text-sm leading-5 text-gray-500">
  //                 If you had a scheduled demo, please email hi@fairkit.io to reschedule. The demo server has been switched off at 9/18/20 11:59 PM CST.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>)
    if (this.state.isSignedIn == null) {
      return (
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else if (this.state.isSignedIn == false) {
      return <SignIn />;
    } else if (this.state.email.indexOf("@mypisd.net") == -1 && this.state.email.indexOf("@pisd.edu") == -1) {
      return <AccountDomain />;
    }
    return <Main role={this.state.role} category={this.state.category} entry={this.state.entry} email={this.state.email}/>;
  }
}
