import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: null,
      loading: true,
      data: [],
      index: 0,
      modal: false,
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  handleChanges = (e) => {
    console.log(e.target.value, e.target.checked, e.target.type);
    if (e.target.type == "radio" || e.target.type == "checkbox") {
      if (e.target.name == "pushType") {
        this.setState({ [e.target.name]: e.target.value }, () => {
          //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
          console.log(this.state);
        });
      } else {
        this.setState({ [e.target.id]: e.target.checked }, () => {
          //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
          console.log(this.state);
        });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value }, () => {
        //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
      });
    }
  };

  loading = () => {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" />
        <h2 className="text-center text-white text-xl font-semibold">
          Loading...
        </h2>
        <p className="w-1/3 text-center text-white">
          Please wait as the user is signed in.
        </p>
      </div>
    );
  };

  fetchData = () => {
    this.setState({ loading: true });

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        if (this.state.key != null) {
          var allgood = true;
           var hours = 0;
          for (var i = 0; i < Object.keys(this.state.data).length; i++) {
            if (this.state.data[Object.keys(this.state.data)[i]].verify != true) {
              allgood = false;
              }
            hours += this.state.data[Object.keys(this.state.data)[i]].value;
          }
          Object.keys(this.state.data).map((val) => {
            //checks if all .verify are true
            if (this.state.data[val].verify != true) {
              allgood = false;
            }
            hours += parseInt(this.state.data[val].value);
          });
          /*if (allgood) {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                fetch(
                  "http://localhost:8000/allOkay?key=" +
                    this.state.key +
                    "&has=" +
                    hours,
                  {
                    headers: {
                      idtoken: idToken,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then(async (data) => {
                    if (data.success) {
                    }
                  })
                  .catch((error) => {
                    alert(
                      "Activation error. Try signing out and signing in again." +
                        error
                    );
                  });
              })
              .catch(function (error) {
                alert(
                  "Activation error. Try signing out and signing in again." +
                    error
                );
              });
          }*/
        }
        fetch("http://localhost:8000/getCardApproval", {
          headers: {
            idtoken: idToken,
          },
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              console.log(data);
              console.log(data, data.data[Object.keys(data.data)[0]]);
              this.setState({
                key: data.key,
                data: data.data,
                index: Object.keys(data.data)[0],
                left: data.left,
                loading: false,
              });
            }
          })
          .catch((error) => {
            alert(
              "1 Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "2 Activation error. Try signing out and signing in again." + error
        );
      });
  };

  setActive = (val) => {
    this.setState({
      index: val,
    });
  };

  renderSubmissions = () => {
    if (this.state.key) {
      return (
        <>
          {Object.keys(this.state.data).map((val) => {
            return (
              <div
                class={
                  this.state.index == val
                    ? "px-3 flex items-center cursor-pointer bg-blue-50"
                    : "px-3 flex items-center cursor-pointer"
                }
                onClick={() => this.setActive(val)}
              >
                <div>
                  {this.state.data[val].verify ? (
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-200 mt-4">
                      <svg
                        class="h-6 w-6 text-green-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  ) : this.state.data[val].verify == undefined ? (
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-200 mt-4">
                      <svg
                        class="w-6 h-6 text-blue-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-200 mt-4">
                      <svg
                        class="w-6 h-6 text-red-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
                <div class="ml-4 flex-1 border-b border-grey-lighter py-4">
                  <div class="flex items-bottom justify-between">
                    <p class="text-grey-darkest">{this.state.data[val].name}</p>
                    <p class="text-xs text-grey-darkest">
                      {this.state.data[val].value}
                    </p>
                  </div>
                  <p class="text-grey-dark mt-1 text-sm">
                    {this.state.data[val].desc}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  approveCard = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "http://localhost:8000/approveCard?key=" +
            this.state.key +
            "&index=" +
            this.state.index +
            "&proper=1",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              this.setState({
                data: data.data,
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  denyCard = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("http://localhost:8000/denyCard" + "?fair=peshfair", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            idtoken: idToken,
          },
          body: JSON.stringify({
            key: this.state.key,
            index: this.state.index,
            name: this.state.data[this.state.index].name,
            message: this.state.message,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              data: data.data,
              message: "",
              modal: false,
            });
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  renderModal = () => {
    return (
      <form onSubmit={this.denyCard}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Deny Reason
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    In order to deny this card, please supply a reason that is
                    emailed to the member.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to member:
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      rows="3"
                      onChange={this.handleChanges}
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => this.setState({ modal: false })}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  noteCard = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("http://localhost:8000/noteCard" + "?fair=peshfair", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            idtoken: idToken,
          },
          body: JSON.stringify({
            key: this.state.key,
            index: this.state.index,
            name: this.state.data[this.state.index].name,
            message: this.state.message,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              modalNote: false,
            });
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  renderModalNote = () => {
    return (
      <form onSubmit={this.noteCard}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modalNote: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Send a Note
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    In order to send a note, please supply a message that is
                    emailed to the member.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to member:
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      required
                      rows="3"
                      onChange={this.handleChanges}
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({ modalNote: false, message: "" })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <div
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {this.state.loading && this.loading()}
        {this.state.modal && this.renderModal()}
        {this.state.modalNote && this.renderModalNote()}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div class="container text-sm text-gray-500">
            <ol class="list-reset py-1 flex bg-grey-light text-grey">
              <li class="pr-1">
                <a href="#" class="no-underline text-indigo">
                  Admin
                </a>
              </li>
              <li>/</li>
              <li class="px-2">
                <a href="#" class="no-underline text-indigo">
                  Card Log Approval
                </a>
              </li>
            </ol>
          </div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Approval Site
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div>
                <div class="container mx-auto">
                  <div class="py-6 pt-0">
                    <button
                      onClick={() => this.fetchData()}
                      type="submit"
                      class="mb-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Release
                    </button>
                    <div class="flex border rounded-xl shadow-lg h-full">
                      <div class="w-1/3 border-r-2 flex flex-col">
                        <div class="py-2 px-4 bg-grey-lightest">
                          <p class="text-grey-darkest text-lg">
                            Logged Cards (only {this.state.left} for review!):
                          </p>
                        </div>

                        <div class="bg-grey-lighter flex-1 overflow-auto pl-2">
                          {this.renderSubmissions()}
                        </div>
                      </div>

                      <div class="w-2/3 flex flex-col">
                        <div class="py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center">
                          <div class="flex">
                            {this.state.data[this.state.index] ? (
                              <a
                                class="mr-6 ml-2 text-red-600"
                                onClick={() => this.setState({ modal: true })}
                              >
                                <svg
                                  class="w-8 h-8"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                              </a>
                            ) : null}
                          </div>
                          <div class="flex items-center">
                            <div class="ml-4">
                              <p class="text-grey-darkest">
                            
                              </p>
                              <p class="text-grey-darker text-xs mt-1">
                                
                              </p>
                            </div>
                          </div>

                          <div class="flex">
                            {this.state.data[this.state.index] ? (
                              <>
                                <a
                                  class="text-green-600"
                                  onClick={() =>
                                    this.setState({ modalNote: true })
                                  }
                                >
                                  <svg
                                    class="w-8 h-8"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                  </svg>
                                </a>
                                <a
                                  class="ml-2 mr-2 text-green-600"
                                  onClick={this.approveCard}
                                >
                                  <svg
                                    class="w-8 h-8"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                </a>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div class="flex-1 overflow-auto">
                          <div class="py-2 px-3">
                            <img
                              src={
                                this.state.data[this.state.index]
                                  ? this.state.data[this.state.index].file !=
                                    undefined
                                    ? "https://media.peshnhs.org/media/" +
                                      this.state.data[this.state.index].file
                                    : "https://i.ibb.co/mXhQnxZ/panda.jpg"
                                  : "https://i.ibb.co/mXhQnxZ/panda.jpg"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
