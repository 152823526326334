import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import { withRouter } from "react-router";
import QRCode from "react-qr-code";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: "",
      slug: "",
      desc: "",
      credits: 0,
      codes: {},
      code: "",
      id: null,
      attendees: {},
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  componentWillUnmount = () => {
    if (this.state.id != null) {
      clearTimeout(this.state.id);
    }
  };

  fetchData = () => {
    firebase
      .database()
      .ref("events/" + this.props.match.params.id)
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          const object = { ...snapshot.val() };
          this.setState({
            ...object,
          });
        } else {
          this.setState({ name: 0 });
        }
      });

    this.genQR();
  };

  genQR = () => {
    var code = Math.random().toString(36).slice(2);
    firebase
      .database()
      .ref("events/" + this.props.match.params.id + "/codes")
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        const object = { ...snapshot.val() };
        const keys = Object.keys(object);
        if (snapshot.val() != null) {
          for (var i = 0; i < keys.length; i++) {
            if ((Date.now() - object[keys[i]]) > 10000) {
              delete object[keys[i]];
            }
          }
        }
        object[code] = Date.now();
        firebase
        .database()
        .ref(`events/${this.props.match.params.id}/codes`)
        .set(object)
        .then(() => {
          this.setState({
            code: "https://portal.peshnhs.org/verify/" + this.state.slug + "/" + code,
            id: setTimeout(() => this.genQR(), 5000)
          });
          
        });
      });
  };

  startEdit = (index) => {
    this.setState({
      editing: true,
      editInd: index,
      name: this.state.credits[index].name,
      value: this.state.credits[index].value,
      desc: this.state.credits[index].desc,
      file: this.state.credits[index].file,
    });
  };

  startModal = (index) => {
    this.setState({
      modal: true,
      name: "",
      value: "",
      desc: "",
      file: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.slug == "") {
      alert("Please add a slug.");
    } else {
      firebase
        .database()
        .ref("events/" + this.props.match.params.id)
        .update(this.state)
        .then(() => {
          alert("Saved!");
        });
    }
  };

  handleEditModal = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .set({
        name: this.state.name,
        value: this.state.value,
        desc: this.state.desc,
        file: this.state.file,
      })
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleDeleteModal = () => {
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .remove()
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  renderOptions = () => {
    return (
      <>
        {Object.keys(this.state.script).map((value, index) => {
          return (
            <>
              <option value={value}>{value}</option>
            </>
          );
        })}
      </>
    );
  };

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {/* {this.state.actionModal && this.renderModal()} */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <NavLink
            to="/events/"
            exact
            className="hidden text-gray-900 hover:text-gray-700 md:block"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </NavLink>
          <h1 className="text-2xl font-semibold text-gray-900">
            Show QR for {this.state.name}
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <form onSubmit={this.handleSubmit}>
            <div className="py-4">
              <div>
                {this.state.code != "" && <QRCode value={this.state.code} size={500} />}
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
export default withRouter(App);
