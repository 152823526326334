import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import JudgeLive from "./liveJudge.js";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      students: {},
      studentsKeys: [],
      name: "",
      desc: "",
      value: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  handleCreate = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://peshfair2020.usere.repl.co/createJudge?fair=peshfair",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              idtoken: idToken,
            },
            body: JSON.stringify({
              name: this.state.name,
              email: this.state.email,
              category: this.state.category
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({modal: false});
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  handleEdit = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://peshfair2020.usere.repl.co/editJudge?fair=peshfair",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              idtoken: idToken,
            },
            body: JSON.stringify({
              name: this.state.name,
              email: this.state.email,
              category: this.state.category,
              uid: this.state.uid
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({editing: false});
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  componentDidMount = () => {
    firebase
      .database()
      .ref("peshfair/judges")
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState({
            students: snapshot.val(),
            studentsKeys: Object.keys(snapshot.val()),
          });
          console.log({
            students: snapshot.val(),
            studentsKeys: Object.keys(snapshot.val()),
          });
        } else {
          this.setState({ students: {}, studentsKeys: [] });
        }
      });
    firebase
      .database()
      .ref("peshfair/judges/" + firebase.auth().currentUser.uid)
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState({
            live: snapshot.val().live,
            joinUrl: snapshot.val().joinUrl,
            student: snapshot.val().student,
          });
        }
      });
    // firebase
    // .database()
    // .ref("students/" + firebase.auth().currentUser.uid)
    // .set([{
    //     name: "TESTING",
    //     desc: "Hello",
    //     value: 3
    // }]);
  };

  listStudents = (obj) => {
    if (obj == null) return;
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      toReturn.push(<p>{obj[arr[i]].name}</p>);
    }
    return toReturn;
  };

  getCumulative = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      sum += obj[arr[i]].value;
    }
    return sum / arr.length;
  };

  renderJudges = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      toReturn.push(<p>{obj[arr[i]].name + " - " + obj[arr[i]].value}</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  rankProjects = () => {
    var values = {};
    var valuesArray = [];
    for (var o = 0; o < this.state.studentsKeys.length; o++) {
      console.log(o);
      if (this.state.students[this.state.studentsKeys[o]].entry != null) {
      } else {
        var arr = Object.keys(
          this.state.students[this.state.studentsKeys[o]].rubric
        );
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
          console.log(arr[i]);
          sum += this.state.students[this.state.studentsKeys[o]].rubric[arr[i]]
            .value;
        }
        values[this.state.studentsKeys[o]] = sum / arr.length;

        console.log(values);
      }
    }
    const leaderboard = Object.assign(
      {},
      ...Object.entries(values)
        .sort(([, a], [, b]) => b - a)
        .map(([p], i) => ({ [i + 1]: p }))
    );

    console.log(leaderboard);
    console.log(Object.values(leaderboard));

    this.setState({ rankedKeys: Object.values(leaderboard) });
    this.setState({ ranks: Object.keys(leaderboard) });

    // this.state.studentsKeys.map((value, index) => {
    //   if (this.state.students[value].entry != null) return null;
    //   var arr = Object.keys(this.state.students[value].rubric);
    //   var sum = 0;
    //   for (var i = 0; i < arr.length; i++) {
    //     console.log(arr[i]);
    //     sum += this.state.students[value].rubric[arr[i]].value;
    //   }
    //   values[value] = (sum / arr.length);

    //   console.log(values);
    // })
  };

  renderTable = () => {
    if (this.state.studentsKeys.length > 0) {
      return (
        <>
          {this.state.studentsKeys.map((value, index) => {
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.students[value].name}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-900">
                  {this.state.students[value].category}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-900">
                  {this.state.students[value].password}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a
                    href={"mailto:" + this.state.students[value].email}
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {this.state.students[value].email}
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a
                    onClick={() => this.startEdit(value)}
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {"Edit"}
                  </a>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  renderCreate = () => {
    return (
      <form onSubmit={this.handleCreate}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Create Judge
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Create judge based on email:
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-4">
                  <label
                    for="email_address"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    required
                    value={this.state.name}
                    onChange={this.handleChanges}
                    class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="about"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="email"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.handleChanges}
                      rows="1"
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium leading-5 text-gray-700">
                    Project Category
                  </label>
                  <select
                    required
                    id="category"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChanges}
                    class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option></option>
                    <option value="Behavorial Sciences">
                      Behavioral Sciences
                    </option>
                    <option value="Biomedical and Health Sciences">
                      Biomedical and Health Sciences
                    </option>
                    <option value="Biomedical Engineering">
                      Biomedical Engineering
                    </option>
                    <option value="Cellular and Molecular Biology">
                      Cellular and Molecular Biology
                    </option>
                    <option value="Computational Biology">
                      Computational Biology
                    </option>
                    <option value="Earth and Environmental Sciences">
                      Earth and Environmental Sciences
                    </option>
                    <option value="Embedded Systems">Embedded Systems</option>
                    <option value="Energy (P/C)">Energy (P/C)</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Microbiology">Microbiology</option>
                    <option value="Physics">Physics</option>
                    <option value="Plant Sciences">Plant Sciences</option>
                    <option value="Robotics and Intelligent Machines">
                      Robotics and Intelligent Machines
                    </option>
                    <option value="Systems Software">Systems Software</option>
                  </select>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </span>
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({
                      modal: false,
                    })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderEdit = () => {
    return (
      <form onSubmit={this.handleEdit}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ editing: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Edit Judge
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Edit judge based on email:
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-4">
                  <label
                    for="email_address"
                    class="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    required
                    value={this.state.name}
                    onChange={this.handleChanges}
                    class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="about"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="email"
                      name="email"
                      required
                      value={this.state.email}
                      onChange={this.handleChanges}
                      rows="1"
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label class="block text-sm font-medium leading-5 text-gray-700">
                    Project Category
                  </label>
                  <select
                    required
                    id="category"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChanges}
                    class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option></option>
                    <option value="Behavioral Sciences">
                      Behavioral Sciences
                    </option>
                    <option value="Biomedical and Health Sciences">
                      Biomedical and Health Sciences
                    </option>
                    <option value="Biomedical Engineering">
                      Biomedical Engineering
                    </option>
                    <option value="Cellular and Molecular Biology">
                      Cellular and Molecular Biology
                    </option>
                    <option value="Computational Biology">
                      Computational Biology
                    </option>
                    <option value="Earth and Environmental Sciences">
                      Earth and Environmental Sciences
                    </option>
                    <option value="Embedded Systems">Embedded Systems</option>
                    <option value="Energy (P/C)">Energy (P/C)</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Microbiology">Microbiology</option>
                    <option value="Physics">Physics</option>
                    <option value="Plant Sciences">Plant Sciences</option>
                    <option value="Robotics and Intelligent Machines">
                      Robotics and Intelligent Machines
                    </option>
                    <option value="Systems Software">Systems Software</option>
                  </select>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </span>
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({
                      editing: false,
                    })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderModal = () => {
    return (
      <form onSubmit={this.handleSubmitModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Send Judge Notifications
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Send notifications to all judges in your category.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="about"
                    class="block text-sm leading-5 font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to all judges in "Category: Chemistry".
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="desc"
                      name="fjlad"
                      required
                      rows="3"
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => this.setState({ modal: false })}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  startCreate = () => {
    this.setState({
      name: "",
      category: "",
      email: "",
      modal: true,
    });
  };

  startEdit = (value) => {
    this.setState({
      name: this.state.students[value].name,
      category: this.state.students[value].category,
      email: this.state.students[value].email,
      editing: true,
      uid: value
    });
  };

  joinJudge = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeetingJudge?" +
            "fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  startJudge = (value) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeeting?tojoin=" +
            value +
            "&fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
    // this.setState({
    //     live: false,
    //     joinUrl: "https://demobbb.fairkit.io/bigbluebutton/api/join?fullName=attendee&meetingID=1&password=secret&checksum=4def2aca8ad74af14da83593ac67886eab441480"
    // });
  };

  render() {
    if (!!this.state.live) {
      return (
        <JudgeLive joinUrl={this.state.joinUrl} student={this.state.student} />
      );
    } else {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderCreate()}
          {this.state.editing && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Judge List
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                {/* <div class="bg-gray-50 shadow sm:rounded-lg mt-4 mb-4">
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Role: Judge
                    </h3>
                    <div class="mt-2">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => this.joinJudge()}
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Join Judge Breakouts
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Advance Students
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Send Judge Notifications
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 sm:mt-6 mb-2">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div> */}
                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Judge Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Category
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Access Code
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                    <div class="mt-0 py-2 px-2 bg-white">
                      <div class="flex justify-end">
                        <span class="ml-3 inline-flex rounded-md shadow-sm">
                          <button
                            onClick={() => this.startCreate()}
                            type="submit"
                            class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                          >
                            Create Judge
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}
