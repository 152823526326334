import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/database';
import JudgeLive from "./liveJudge.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      events: {},
      eventsKeys: [],
      name: "",
      desc: "",
      slug: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .database()
      .ref("events")
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          const object = { ...snapshot.val() };

          this.setState({
            events: snapshot.val(),
            eventsKeys: Object.keys(snapshot.val()),
          });
        } else {
          this.setState({ events: {}, eventsKeys: [] });
        }
      });
  };

  addEvent = (e) => {
    firebase
      .database()
      .ref("events/" + this.state.slug)
      .update({
        name: "Untitled",
        slug: this.state.slug,
        desc: "",
        credits: 0,
        codes: {},
        attendees: {},
      })
      .then(() => {
        window.location = "/events/" + this.state.slug;
      });
  };

  getCumulative = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      sum += this.calcSum(obj[arr[i]]);
    }
    return sum / arr.length;
  };

  renderJudges = (obj, value) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      let judge = arr[i];
      toReturn.push(
        <p
          class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
          onClick={() => this.startEdit(judge, value)}
        >
          {obj[arr[i]].name + " - " + this.calcSum(obj[arr[i]])}
        </p>
      );
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  renderStudents = (arr) => {
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      // toReturn.push(<p>{arr[i]}</p>);
      toReturn.push(<p>Replaced Name</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  renderLive = () => {
    if (this.state.eventsKeys.length > 0) {
      return (
        <>
          {this.state.eventsKeys.map((value, index) => {
            if (this.state.events[value].entry != null) return null;
            if (!this.state.events[value].live) return null;
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.events[value].firstname +
                    this.state.events[value].lastname}
                  <br />
                  <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs leading-4 font-medium bg-blue-100 rounded-full">
                    {this.state.events[value].idnumber}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {!this.state.events[value].live ? (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">
                      Free for Judging
                    </span>
                  ) : (
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs leading-4 font-medium bg-red-300 rounded-full">
                      {"Live with " + this.state.events[value].activeJudge}
                    </span>
                  )}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {"test"}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  test
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    test
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    test
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <a
                    href={this.state.events[value].joinUrl}
                    target="_blank"
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {"Join"}
                  </a>
                </td>
              </tr>
            );
          })}

          <tr>
            <span class="mx-6 my-24 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Live projects will populate above.
            </span>
          </tr>
        </>
      );
    }
  };

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  renderFiles = (obj) => {
    return (
      <>
        {Object.keys(obj).map((value, index) => {
          return (
            <p class="text-left">
              <a
                href={
                  "https://peshfair2020.usere.repl.co/media/" + value + ".pdf"
                }
                class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                target="_blank"
              >
                {obj[value]}
              </a>
            </p>
          );
        })}
      </>
    );
  };

  renderTable = () => {
    if (this.state.eventsKeys.length > 0) {
      return (
        <>
          {this.state.eventsKeys.map((value, index) => {
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.events[value].name}
                </td>

                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <NavLink
                    to={"/events/" + value}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Manage
                  </NavLink>
                  <NavLink
                    to={"/events/" + value + "/showCode"}
                    className="ml-2 text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    View QR
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  render() {
    if (!!this.state.live) {
      return (
        <JudgeLive joinUrl={this.state.joinUrl} student={this.state.student} />
      );
    } else {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Event Viewer
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Add Event
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Type event name at the bottom of the table and click
                          add.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <h2 className="text-lg font-semibold text-gray-900 ml-2 mb-2">
                    All Students
                  </h2>
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                    <div class="flex justify-start p-2">
                      <div class="justify-start flex-1">
                        <div class="col-span-2 flex align-center">
                          <input
                            id="slug"
                            name="slug"
                            value={this.state.slug}
                            onChange={this.handleChanges}
                            class="form-input block w-64 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                          <span class="ml-3 rounded-md justify-end flex-1">
                            <button
                              onClick={(e) => this.addEvent(e)}
                              placeholder="Block name"
                              class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                            >
                              Add Event
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}
