import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';let audio = new Audio("https://peshfair2020.usere.repl.co/media/ring.mp3");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showModal: false
    };
  }

  playAudio = () => {
    audio.currentTime = 0;
    audio.play()
  }

  componentDidMount = () => {
    console.log(
      this.props.entry != 0 ? this.props.entry : firebase.auth().currentUser.uid
    );
    firebase
      .database()
      .ref(
        "peshfair/entries/" +
          (this.props.entry != 0
            ? this.props.entry
            : firebase.auth().currentUser.uid)
      )
      .on("value", (snapshot) => {
        if (snapshot.val() != undefined) {
          console.log(snapshot.val());
          this.setState({
            showModal: snapshot.val().live,
            show: false,
            joinUrl: snapshot.val().joinUrl,
            title: snapshot.val().title,
            category: snapshot.val().category,
            leader: snapshot.val().firstname + " " + snapshot.val().lastname,
            entry:
            (this.props.entry != 0
              ? this.props.entry
              : firebase.auth().currentUser.uid)
          });
          if (snapshot.val().live) {
           this.playAudio();
            setTimeout(() => {
              this.setState({
                show: snapshot.val().live,
                showModal: false,
              });
            }, 8000);
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then((idToken) => {
                fetch(
                  "https://peshnhsactivator-1.usere.repl.co/checkMeetingStudent?tojoin=" +
                  (this.props.entry != 0
                    ? this.props.entry
                    : firebase.auth().currentUser.uid) + "&fair=peshfair",
                  {
                    headers: {
                      idtoken: idToken,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.success) {
                    }
                  })
                  .catch((error) => {
                    alert(
                      "Activation error. Try signing out and signing in again." +
                        error
                    );
                  });
              })
              .catch(function (error) {
                alert(
                  "Activation error. Try signing out and signing in again." +
                    error
                );
              });
          }
        }
      });
  };


  renderModal = () => {
    return (
      <div>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Good luck!
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    This page will refresh in 8 seconds to bring you into the judging room. Good luck!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.show == true && this.state.joinUrl != null) {
      return (
        <iframe
          style={{ height: "100%", width: "100%" }}
          sandbox="allow-forms allow-scripts allow-same-origin"
          allow="microphone; camera; fullscreen"
          src={this.state.joinUrl}
        ></iframe>
      );
    }
    return (
      <>
      {this.state.showModal && this.renderModal()}
      <div class="bg-gray-100 h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Judging session
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 sm:mx-3">
                      The judging window has begun. Please stay on this page,
                      you will automatically be redirected when a judge has
                      selected you for an interview.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm"></span>
                </div>
              </div>
              <div class="bg-white shadow sm:rounded-lg mt-4 p-6">
                <div>
                  <p>
                    <span
                      style={{
                        fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
                        fontSize: "26px",
                      }}
                    >
                      <strong>Check now!</strong>
                    </span>
                  </p>
                  <br />
                  <span
                    style={{
                      fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
                    }}
                  >
                    Check your details now!
                    <p>YOUR PROJECT: {this.state.title}</p>
                    <p>YOUR CATEGORY: {this.state.category}</p>
                    <p>
                      YOUR STATUS:{" "}
                      {this.props.entry != 0
                        ? "Group Member"
                        : "Project Leader"}
                    </p>
                    <p>YOUR PROJECT LEADER: {this.state.leader}</p>
                    <button
                      onClick={() => this.playAudio()}
                      type="submit"
                      class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Test Chime
                    </button>
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
