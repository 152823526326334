import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      room: "https://meet.jit.si/helloooej23",
    };
  }

  componentDidMount = () => {
    console.log("Hello");
  };

  render() {
    return (
      <div
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Category: {this.props.category}
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                  <div>
                    <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                      You are signed up to judge {this.props.category} for
                      today, 12/2.
                    </h3>
                    <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                      <p>
                        Please join our Zoom judge orientation. Refer to your
                        email for the join link, or click here:
                        <a
                          href="https://planoisd.zoom.us/j/94930061008?pwd=aXhMbVZxd3liYXBBZU43SWY1N3BtZz09"
                          target="_blank"
                        >
                          Join Zoom Meeting
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center lg:mr-2"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
