import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      rubric: "exp",
    };
  }

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://peshfair2020.usere.repl.co/submitRubric?student=" +
            this.props.student +
            "&fair=peshfair",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              idtoken: idToken,
            },
            body: JSON.stringify(this.state),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again." + error
        );
      });
  };

  componentDidMount = () => {
    if (this.props.joinUrl != undefined) {
      if (this.props.student == "breakout") {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            fetch(
              "https://peshfair2020.usere.repl.co/checkMeetingJudge?" +
                "&fair=peshfair",
              {
                headers: {
                  idtoken: idToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                }
              })
              .catch((error) => {
                alert(
                  "Activation error. Try signing out and signing in again." +
                    error
                );
              });
          })
          .catch(function (error) {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
        this.setState({ show: true });
      } else {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            fetch(
              "https://peshfair2020.usere.repl.co/checkMeeting?tojoin=" +
                this.props.student +
                "&fair=peshfair",
              {
                headers: {
                  idtoken: idToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                }
              })
              .catch((error) => {
                alert(
                  "Activation error. Try signing out and signing in again." +
                    error
                );
              });
          })
          .catch(function (error) {
            alert(
              "Activation error. Try signing out and signing in again." + error
            );
          });
        this.setState({ show: true });
      }
    }
    firebase
      .database()
      .ref("peshfair/entries/" + this.props.student)
      .on("value", (snapshot) => {
        if (snapshot.val() != null) {
          if (snapshot.val().files != null) {
            this.setState({
              files: snapshot.val().files,
            });
          }
        }
      });
  };

  changeRubric = (number) => {
    this.setState({
      rubric: number,
      purpose: "",
      contribution: "",
      method: "",
      datacollection: "",
      variables: "",
      systematic: "",
      reproducibility: "",
      math: "",
      collected: "",
      creativity: "",
      logical: "",
      clarity: "",
      documentation: "",
      science: "",
      interpretation: "",
      independence: "",
      impact: "",
      quality: "",
      criteria: "",
      constraints: "",
      alternatives: "",
      identification: "",
      prototype: "",
      intended: "",
      reproducibility: "",
      skill: "",
      responses: "",
      comments: ""
    })
  }

  renderFiles = (obj) => {
    if (obj == null) return "N/A";
    return (
      <>
        {Object.keys(obj).map((value, index) => {
          return (
            <p class="text-left">
              <a
                href={
                  "https://peshfair2020.usere.repl.co/media/" + value + ".pdf"
                }
                class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                target="_blank"
              >
                {obj[value]}
              </a>
            </p>
          );
        })}
      </>
    );
  };

  closeMeeting = () => {
    if (this.props.student == "breakout") {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          fetch(
            "https://peshfair2020.usere.repl.co/closeMeetingJudge?" +
              "&fair=peshfair",
            {
              headers: {
                idtoken: idToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
              }
            })
            .catch((error) => {
              alert(
                "Activation error. Try signing out and signing in again." +
                  error
              );
            });
        })
        .catch(function (error) {
          alert(
            "Activation error. Try signing out and signing in again." + error
          );
        });
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          fetch(
            "https://peshfair2020.usere.repl.co/closeMeeting?tojoin=" +
              this.props.student +
              "&fair=peshfair",
            {
              headers: {
                idtoken: idToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
              }
            })
            .catch((error) => {
              alert(
                "Activation error. Try signing out and signing in again." +
                  error
              );
            });
        })
        .catch(function (error) {
          alert(
            "Activation error. Try signing out and signing in again." + error
          );
        });
    }
  };

  renderLifeSciences = () => {
    return (
      <>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Research Question (10 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clear and focused purpose
            </label>
            <select
              required
              id="purpose"
              name="purpose"
              value={this.state.purpose}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Identified contribution to field of study
            </label>
            <select
              required
              id="contribution"
              name="contribution"
              value={this.state.contribution}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Testable using scientific methods
            </label>
            <select
              required
              id="method"
              name="method"
              value={this.state.method}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Design and Methodology (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Well-designed plan and data collection methods
            </label>
            <select
              required
              id="datacollection"
              name="datacollection"
              value={this.state.datacollection}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Variables and controls defined, appropriate, and complete
            </label>
            <select
              required
              id="variables"
              name="variables"
              value={this.state.variables}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Execution: Data Collection, Analysis, and Interpretation (20
              Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Systematic data collection and analysis
            </label>
            <select
              required
              id="systematic"
              name="systematic"
              value={this.state.systematic}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Reproducibility of results
            </label>
            <select
              required
              id="reproducibility"
              name="reproducibility"
              value={this.state.reproducibility}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Appropriate application of mathematical and statistical methods
            </label>
            <select
              required
              id="math"
              name="math"
              value={this.state.math}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Sufficient data collected to support interpretation and
              conclusions
            </label>
            <select
              required
              id="collected"
              name="collected"
              value={this.state.collected}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Creativity (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Project demonstrates creativity in one or more of the above
              criteria
            </label>
            <select
              required
              id="creativity"
              name="creativity"
              value={this.state.creativity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Presentation (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Logical organization of material
            </label>
            <select
              required
              id="logical"
              name="logical"
              value={this.state.logical}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clarity of graphics and legends
            </label>
            <select
              required
              id="clarity"
              name="clarity"
              value={this.state.clarity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Supporting documentation displayed
            </label>
            <select
              required
              id="documentation"
              name="documentation"
              value={this.state.documentation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Interview (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding of basic science relevant to project
            </label>
            <select
              required
              id="science"
              name="science"
              value={this.state.science}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding interpretation & limitations of results &
              conclusions
            </label>
            <select
              required
              id="interpretation"
              name="interpretation"
              value={this.state.interpretation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Degree of independence in conducting project
            </label>
            <select
              required
              id="independence"
              name="independence"
              value={this.state.independence}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Recognition of potential impact in science, society and/or
              economics
            </label>
            <select
              required
              id="impact"
              name="impact"
              value={this.state.impact}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Quality of ideas for further research
            </label>
            <select
              required
              id="quality"
              name="quality"
              value={this.state.quality}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Judge Comments
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Comments will be sent to students post-fair.
            </label>
            <textarea
              required
              id="comments"
              name="comments"
              value={this.state.comments}
              onChange={this.handleChanges}
              class="mt-1 block w-full h-40 py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            ></textarea>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
      </>
    );
  };

  renderEngineering = () => {
    return (
      <>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Research Problem (10 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Description of a Practical Need or Problem to Be Solved
            </label>
            <select
              required
              id="purpose"
              name="purpose"
              value={this.state.purpose}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Definition of Criteria for Proposed Solution
            </label>
            <select
              required
              id="criteria"
              name="criteria"
              value={this.state.criteria}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Explanation of Constraints
            </label>
            <select
              required
              id="constraints"
              name="constraints"
              value={this.state.constraints}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Design and Methodology (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Exploration of Alternatives to Answer Need or Problem
            </label>
            <select
              required
              id="alternatives"
              name="alternatives"
              value={this.state.alternatives}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Identification of a Solution
            </label>
            <select
              required
              id="identification"
              name="identification"
              value={this.state.identification}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Development of a Prototype/Model
            </label>
            <select
              required
              id="prototype"
              name="prototype"
              value={this.state.prototype}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Execution: Data Collection, Analysis, and Interpretation (20
              Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype Demonstrates Intended Design
            </label>
            <select
              required
              id="intended"
              name="intended"
              value={this.state.intended}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype has Been Tested in Multiple Condition/Trials
            </label>
            <select
              required
              id="reproducibility"
              name="reproducibility"
              value={this.state.reproducibility}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Prototype Demonstrates Engineering Skill and Completeness
            </label>
            <select
              required
              id="skill"
              name="skill"
              value={this.state.skill}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Creativity (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Project demonstrates creativity in one or more of the above
              criteria
            </label>
            <select
              required
              id="creativity"
              name="creativity"
              value={this.state.creativity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Presentation (15 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
             Logical Organization of Material
            </label>
            <select
              required
              id="logical"
              name="logical"
              value={this.state.logical}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Clarity of Graphics and Legends
            </label>
            <select
              required
              id="clarity"
              name="clarity"
              value={this.state.clarity}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Supporting Documentation Displayed
            </label>
            <select
              required
              id="documentation"
              name="documentation"
              value={this.state.documentation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Interview (20 Points)
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding of Basic Science Relevant to Project
            </label>
            <select
              required
              id="science"
              name="science"
              value={this.state.science}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Understanding Interpretation and Limitations of Results and Conclusions
            </label>
            <select
              required
              id="interpretation"
              name="interpretation"
              value={this.state.interpretation}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Degree of Independence in Conducting Project
            </label>
            <select
              required
              id="independence"
              name="independence"
              value={this.state.independence}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Recognition of potential impact in science, society and/or
              economics
            </label>
            <select
              required
              id="impact"
              name="impact"
              value={this.state.impact}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Quality of ideas for further research
            </label>
            <select
              required
              id="quality"
              name="quality"
              value={this.state.quality}
              onChange={this.handleChanges}
              class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
              <option></option>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
        <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Judge Comments
            </h3>
            <label
              for="country"
              class="block text-sm font-medium leading-5 text-gray-700 mt-2"
            >
              Comments will be sent to students post-fair.
            </label>
            <textarea
              required
              id="comments"
              name="comments"
              value={this.state.comments}
              onChange={this.handleChanges}
              class="mt-1 block w-full h-40 py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            ></textarea>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <div class="grid grid-cols-6 gap-6"></div>
          </div>
        </div>
      </>
    );
  };

  render() {
    if (this.state.show) {
      return (
        <div className="flex h-screen">
          <iframe
            style={{ height: "100%", width: "75%" }}
            sandbox="allow-forms allow-scripts allow-same-origin"
            allow="microphone; camera; fullscreen"
            src={this.props.joinUrl}
          ></iframe>
          <div
            className="overflow-y-scroll py-2"
            style={{ width: "25%", backgroundColor: "#f2f6f8" }}
          >
            <div className="px-4">
              <form onSubmit={this.handleSubmit}>
                <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
                  <div class="flex content-center">
                    <a
                      class="flex-1 content-center items-center"
                      onClick={this.closeMeeting}
                    >
                      <svg
                        class="w-6 h-6 m-auto"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        ></path>
                      </svg>
                    </a>
                    <a
                      class="flex-1 content-center items-center"
                      href="https://planoisd.zoom.us/j/94930061008?pwd=aXhMbVZxd3liYXBBZU43SWY1N3BtZz09"
                      target="_blank"
                    >
                      <svg
                        class="w-6 h-6 m-auto"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="hidden sm:block mt-3">
                  <nav class="flex align-center justify-center -ml-2">
                    <a
                      href="#"
                      onClick={() => this.changeRubric("exp")}
                      class={
                        this.state.rubric == "exp"
                          ? "px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700 outline-none text-indigo-600 bg-indigo-200"
                          : "px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700"
                      }
                    >
                      Life Sciences
                    </a>
                    <a
                      href="#"
                      onClick={() => this.changeRubric("eng")}
                      class={
                        this.state.rubric == "eng"
                          ? "ml-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700 outline-none text-indigo-600 bg-indigo-200"
                          : "ml-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700"
                      }
                    >
                      Engineering
                    </a>
                  </nav>
                </div>
                <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
                  <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      {this.state.rubric == "exp"
                        ? "Life Sciences Rubric"
                        : "Engineering"}
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                      Fill out the following form for each competitor. Click on
                      the help icon above to access the live chat for support.
                      Click on the files below to open them in a new tab.
                    </p>
                  </div>
                  <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-6 gap-6"></div>
                  </div>
                </div>
                <div class="mt-3 bg-white shadow px-2 py-3 sm:rounded-lg sm:p-6">
                  <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Student Files
                    </h3>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                      {this.renderFiles(this.state.files)}
                    </p>
                  </div>
                  <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="grid grid-cols-6 gap-6"></div>
                  </div>
                </div>
                {this.state.rubric == "exp" ? this.renderLifeSciences() : this.renderEngineering()}
                <div class="mt-3 bg-white shadow sm:rounded-lg mb-2">
                  <span class="flex w-full rounded-md shadow-sm">
                    <button
                      type="submit"
                      class="inline-flex mx-0 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      Save and exit call
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div class="bg-gray-100 h-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    You're all set!
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 sm:mx-3">
                      Joining room now!
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
