import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  return (
    <label
      class="text-center"
      style={{ cursor: "pointer", width: "100%", height: "100%" }}
    >
      <p>
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="mt-1 text-sm text-gray-600">
          <span class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out pr-1">
            Upload a file
          </span>
          or drag and drop
        </p>
        <p class="mt-1 text-xs text-gray-500">PDF up to 20MB</p>
      </p>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={async (e) => {
          const target = e.target;
          const chosenFiles = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          //@ts-ignore
          target.value = null;
        }}
      />
    </label>
  );
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChanges = (e) => {
    console.log(e.target.value, e.target.checked, e.target.type);
    if (e.target.type == "radio" || e.target.type == "checkbox") {
      if (e.target.name == "pushType") {
        this.setState({ [e.target.name]: e.target.value }, () => {
          //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
          console.log(this.state);
        });
      } else {
        this.setState({ [e.target.id]: e.target.checked }, () => {
          //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
          console.log(this.state);
        });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value }, () => {
        //this.props.firebase.database().ref('submissions/officer/').child(this.props.firebase.auth().currentUser.uid).set({...this.state});
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true }, () => {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          fetch(
            "https://peshfair2020.usere.repl.co/requestActivation" +
              "?fair=peshfair",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                idtoken: idToken,
              },
              body: JSON.stringify(this.state),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                console.log(idToken);
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then(function (newToken) {
                    // firebase.auth().signInAndRetrieveDataWithCredential
                    // firebase.auth().signInWithCustomToken(idToken);
                  });
              }
            })
            .catch((error) => {
              alert(
                "Activation error. Try signing out and signing in again." +
                  error
              );
            });
        })
        .catch(function (error) {
          alert(
            "Activation error. Try signing out and signing in again." + error
          );
        });
    });
  };

  showFiles = () => {
    if (Object.keys(this.state.files).length > 0) {
      return (
        <>
          <label
            for="email_address"
            class="block text-sm font-medium leading-5 text-gray-700 text-left mb-2"
          >
            Uploaded Files
          </label>
          <ul id="gallery" class="flex flex-1 flex-wrap -m-1 mb-1">
            {Object.keys(this.state.files).map((value, index) => {
              return (
                <li class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
                  <article
                    tabindex="0"
                    class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"
                  >
                    <img
                      alt="upload preview"
                      class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed"
                    />

                    <section class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                      <a
                        href={
                          "https://peshfair2020.usere.repl.co/media/" +
                          value +
                          ".pdf"
                        }
                        target="_blank"
                      >
                        <h1 class="flex-1 group-hover:text-blue-800">
                          {this.state.files[value]}
                        </h1>
                      </a>
                      <div class="flex">
                        <span class="p-1 text-blue-800">
                          <i>
                            <svg
                              class="fill-current w-4 h-4 ml-auto pt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z"></path>
                            </svg>
                          </i>
                        </span>
                        <p class="p-1 size text-xs text-gray-700">PDF</p>
                        <p class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800">
                          <svg
                            class="pointer-events-none fill-current w-4 h-4 ml-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              class="pointer-events-none"
                              d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                            ></path>
                          </svg>
                        </p>
                      </div>
                    </section>
                  </article>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  SingleUpload = (file) => {
    const toast = (innerHTML) => {
      try {
        const el = document.getElementById("toast");
        el.innerHTML = innerHTML;
      } catch {}
    };

    const getUploadParams = async () => {
      let idToken = await getID();
      return {
        url: "https://peshfair2020.usere.repl.co/uploadFiles",
        headers: {
          idtoken: idToken,
        },
      };
    };

    const getID = async () => {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .currentUser.getIdToken(false)
          .then((idToken, err) => {
            return resolve(idToken);
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
            reject(error);
          });
      });
    };

    const handleChangeStatus = ({ meta, remove, xhr }, status) => {
      console.log(status);
      if (status === "done") {
        if (xhr != undefined && xhr.responseText) {
          let parsed = JSON.parse(xhr.response);
          if (parsed.success == 1) {
            // toast(
            //   `${meta.name} uploaded! <a target="_blank" class="text-blue-900" href="https://peshnhsactivator-1.usere.repl.co/media/${parsed.file}">${parsed.original}</a>`
            // );
            remove();
            const fileName = parsed.file.replace(".pdf", "");
            this.setState(
              {
                files: { ...this.state.files, [fileName]: parsed.original },
              },
              () => {
                firebase
                  .database()
                  .ref(
                    "/peshfair/entries/" +
                      firebase.auth().currentUser.uid +
                      "/files"
                  )
                  .set(this.state.files);
              }
            );
          } else {
            toast(`${meta.name} failed! Check filesize and filetype!`);
            remove();
          }
        } else {
          toast(`${meta.name} failed! Check filesize and filetype!`);
          remove();
        }
      } else if (status === "aborted") {
        toast(`${meta.name}, upload failed...`);
      }
    };

    const handleStart = () => {
      if (file != null) {
        setTimeout(
          () =>
            toast(
              `Attached file: <a target="_blank" class="text-blue-900" href="https://peshnhsactivator-1.usere.repl.co/media/${file}">${this.state.original}</a>`
            ),
          100
        );
      }
    };
    return (
      <React.Fragment>
        <div id="toast" class="mt-0 text-sm text-gray-500"></div>
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          maxFiles={1}
          maxSizeBytes={20971520}
          multiple={false}
          canCancel={false}
          inputContent="Drop A File"
          tex
          styles={{
            dropzoneActive: { borderColor: "green" },
          }}
          classNames={{
            dropzone:
              "mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md",
          }}
          InputComponent={Input}
        />
        {handleStart()}
      </React.Fragment>
    );
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6 bg-gray-100 h-full"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Registration</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <form onSubmit={this.handleSubmit}>
            <div className="py-4">
              <div>
                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please use a personal email account that you can receive
                        mail at.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            First Name
                          </label>
                          <input
                            required
                            id="firstname"
                            name="firstname"
                            value={this.state.firstname}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Last Name
                          </label>
                          <input
                            required
                            id="lastname"
                            name="lastname"
                            type="text"
                            value={this.state.lastname}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Personal Email Address
                          </label>
                          <input
                            required
                            id="email"
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Project Type
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Number of students in your project.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium leading-5 text-gray-700">
                            Project Type
                          </label>
                          <select
                            required
                            id="projecttype"
                            name="projecttype"
                            value={this.state.projecttype}
                            onChange={this.handleChanges}
                            class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option></option>
                            <option value="1">Create Project</option>
                            <option value="2">Join a Project</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Project Information
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please fill in your project details.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <label
                        for="email_address"
                        class={
                          this.state.projecttype == null
                            ? "block text-sm font-medium leading-5 text-gray-700"
                            : "block text-sm font-medium leading-5 text-gray-700 hidden"
                        }
                      >
                        Please select a project type.
                      </label>
                      <div class="grid grid-cols-6 gap-6">
                        <div
                          class={
                            this.state.projecttype == "2"
                              ? "col-span-6 sm:col-span-4 pb-3"
                              : "col-span-6 sm:col-span-4 hidden"
                          }
                        >
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Project Join Code
                          </label>
                          <input
                            id="joincode"
                            name="joincode"
                            type="text"
                            value={this.state.joincode}
                            onChange={this.handleChanges}
                            class={
                              "mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            }
                          />
                        </div>
                      </div>
                      <div class="grid grid-cols-6 gap-6">
                        <div
                          class={
                            this.state.projecttype == null
                              ? "col-span-6 sm:col-span-4 hidden"
                              : "col-span-6 sm:col-span-4"
                          }
                        >
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Project Title
                          </label>
                          <input
                            required
                            id="title"
                            name="title"
                            type="text"
                            disabled={
                              this.state.projecttype == "2" ? true : false
                            }
                            value={this.state.title}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div
                          class={
                            this.state.projecttype == null
                              ? "col-span-6 sm:col-span-4 hidden"
                              : "col-span-6 sm:col-span-4"
                          }
                        >
                          <label class="block text-sm font-medium leading-5 text-gray-700">
                            Project Category
                          </label>
                          <select
                            required
                            id="category"
                            name="category"
                            disabled={
                              this.state.projecttype == "2" ? true : false
                            }
                            value={this.state.category}
                            onChange={this.handleChanges}
                            class="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option></option>
                            <option value="Behavioral Sciences">
                            Behavioral Sciences
                            </option>
                            <option value="Biomedical and Health Sciences">
                              Biomedical and Health Sciences
                            </option>
                            <option value="Biomedical Engineering">
                              Biomedical Engineering
                            </option>
                            <option value="Cellular and Molecular Biology">
                              Cellular and Molecular Biology
                            </option>
                            <option value="Computational Biology">
                              Computational Biology
                            </option>
                            <option value="Earth and Environmental Sciences">
                              Earth and Environmental Sciences
                            </option>
                            <option value="Embedded Systems">
                              Embedded Systems
                            </option>
                            <option value="Energy (P/C)">Energy (P/C)</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Mathematics">Mathematics</option>
                            <option value="Microbiology">Microbiology</option>
                            <option value="Physics">Physics</option>
                            <option value="Plant Sciences">
                              Plant Sciences
                            </option>
                            <option value="Robotics and Intelligent Machines">
                              Robotics and Intelligent Machines
                            </option>
                            <option value="Systems Software">
                              Systems Software
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-end">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Save
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Project Files
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Please use this tool to add student uploads to your
                        project.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <label
                        class="text-center"
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {this.showFiles()}
                        {this.SingleUpload(this.state.file)}
                      </label>
                    </div>
                  </div>
                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-end">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Save
                        </button>
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
