import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-scroll pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Demo Fair: Home
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="videoWrapper">
                <iframe
                  class="video"
                  src="https://www.youtube.com/embed/EEIk7gwjgIM?controls=0&autoplay=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              {/* <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Attendance
                  </h3>
                  <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
                    <p>Please click "Check In" below.</p>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div class="mt-5 sm:mt-6">
                      <span class="flex w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Go back to dashboard
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="bg-white shadow sm:rounded-lg mt-4 p-6">
                <p>
                  <span
                    style={{
                      fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
                      fontSize: "26px",
                    }}
                  >
                    <strong>Welcome to the 2020 Demo Fair!</strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: '"Trebuchet MS", Helvetica, sans-serif',
                    }}
                  >
                    More information will be populated here on fair day.
                  </span>
                </p>
                <br />
                <p>
                  <br />
                </p>
              </div>

              <div class="bg-white shadow sm:rounded-lg mt-4">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mt-4">
                  <svg
                    class="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Assistance
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                      Click below to speak with an event organizer.
                    </p>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 mb-4">
                  <span class="flex w-full rounded-md shadow-sm">
                    <a
                      href="https://tawk.to/chat/5fc309a7920fc91564cba344/default"
                      target="_blank"
                      class="inline-flex mx-6 justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      Support
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
