import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import JudgeLive from "./liveJudge.js";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      students: {},
      studentsKeys: [],
      name: "",
      desc: "",
      value: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .database()
      .ref("peshfair/entries")
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState(
            {
              students: snapshot.val(),
              studentsKeys: Object.keys(snapshot.val()),
            },
            this.rankProjects
          );
          console.log({
            students: snapshot.val(),
            studentsKeys: Object.keys(snapshot.val()),
          });
        } else {
          this.setState({ students: {}, studentsKeys: [] });
        }
      });
    firebase
      .database()
      .ref("peshfair/judges/" + firebase.auth().currentUser.uid)
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          this.setState({
            live: snapshot.val().live,
            joinUrl: snapshot.val().joinUrl,
            student: snapshot.val().student,
          });
        }
      });
    // firebase
    // .database()
    // .ref("students/" + firebase.auth().currentUser.uid)
    // .set([{
    //     name: "TESTING",
    //     desc: "Hello",
    //     value: 3
    // }]);
  };

  getCumulative = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      sum += obj[arr[i]].value;
    }
    return sum / arr.length;
  };

  renderJudges = (obj) => {
    console.log(obj);
    var arr = Object.keys(obj);
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      toReturn.push(<p>{obj[arr[i]].name + " - " + obj[arr[i]].value}</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  rankProjects = () => {
    var values = {};
    var valuesArray = [];
    for (var o = 0; o < this.state.studentsKeys.length; o++) {
      console.log(o);
      if (
        this.state.students[this.state.studentsKeys[o]].entry != null ||
        this.state.students[this.state.studentsKeys[o]].rubric == null
      ) {
      } else {
        var arr = Object.keys(
          this.state.students[this.state.studentsKeys[o]].rubric
        );
        var sum = 0;
        for (var i = 0; i < arr.length; i++) {
          console.log(arr[i]);
          sum += this.state.students[this.state.studentsKeys[o]].rubric[arr[i]]
            .value;
        }
        values[this.state.studentsKeys[o]] = sum / arr.length;

        console.log(values);
      }
    }
    const leaderboard = Object.assign(
      {},
      ...Object.entries(values)
        .sort(([, a], [, b]) => b - a)
        .map(([p], i) => ({ [i + 1]: p }))
    );

    console.log(leaderboard);
    console.log(Object.values(leaderboard));

    this.setState({ rankedKeys: Object.values(leaderboard) });
    this.setState({ ranks: Object.keys(leaderboard) });

    // this.state.studentsKeys.map((value, index) => {
    //   if (this.state.students[value].entry != null) return null;
    //   var arr = Object.keys(this.state.students[value].rubric);
    //   var sum = 0;
    //   for (var i = 0; i < arr.length; i++) {
    //     console.log(arr[i]);
    //     sum += this.state.students[value].rubric[arr[i]].value;
    //   }
    //   values[value] = (sum / arr.length);

    //   console.log(values);
    // })
  };

  renderStudents = (arr) => {
    var toReturn = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(arr[i]);
      // toReturn.push(<p>{arr[i]}</p>);
      toReturn.push(<p>Replaced Name</p>);
      // if (i != arr.length - 1) {
      //   toReturn += "<br />";
      // }
    }
    return toReturn;
  };

  renderFiles = (obj) => {
    return (
      <>
        {Object.keys(obj).map((value, index) => {
          return (
            <p class="text-left">
              <a
                href={
                  "https://peshfair2020.usere.repl.co/media/" + value + ".pdf"
                }
                class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                target="_blank"
              >
                {obj[value]}
              </a>
            </p>
          );
        })}
      </>
    );
  };

  renderTable = () => {
    if (this.state.rankedKeys.length > 0) {
      return (
        <>
          {this.state.rankedKeys.map((value, index) => {
            if (this.state.students[value].entry != null) return null;
            return (
              <tr>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.ranks[index]}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].title}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.renderStudents(this.state.students[value].students)}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].files
                    ? this.renderFiles(this.state.students[value].files)
                    : null}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a
                    href="#"
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    {this.renderJudges(this.state.students[value].rubric)}
                  </a>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                  <a href="#" class="focus:outline-none">
                    {this.state.students[value].rubric
                      ? this.getCumulative(this.state.students[value].rubric)
                      : "Not Yet Judged"}
                  </a>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  renderModal = () => {
    return (
      <form onSubmit={this.handleSubmitModal}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </div>
      </form>
    );
  };

  joinJudge = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeetingJudge?" +
            "fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
  };

  startJudge = (value) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function (idToken) {
        fetch(
          "https://peshnhsactivator-1.usere.repl.co/startMeeting?tojoin=" +
            value +
            "&fair=peshfair",
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
            }
          })
          .catch((error) => {
            alert("Activation error. Try signing out and signing in again.");
          });
      })
      .catch(function (error) {
        alert("Activation error. Try signing out and signing in again.");
      });
    // this.setState({
    //     live: false,
    //     joinUrl: "https://demobbb.fairkit.io/bigbluebutton/api/join?fullName=attendee&meetingID=1&password=secret&checksum=4def2aca8ad74af14da83593ac67886eab441480"
    // });
  };

  render() {
    if (!!this.state.live) {
      return (
        <JudgeLive joinUrl={this.state.joinUrl} student={this.state.student} />
      );
    } else {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderModal()}
          {this.state.editing && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Ranking: All Categories
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                {/* <div class="bg-gray-50 shadow sm:rounded-lg mt-4 mb-4">
                  <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Role: Judge
                    </h3>
                    <div class="mt-2">
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => this.joinJudge()}
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Join Judge Breakouts
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Advance Students
                        </button>
                      </span>
                      <span class="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() =>
                            alert("There is only 1 student available.")
                          }
                          type="submit"
                          class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Send Judge Notifications
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="mt-2 sm:mt-6 mb-2">
                    <span class="flex w-full rounded-md shadow-sm"></span>
                  </div>
                </div> */}

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Rank #
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Project
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Students
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Files
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Rubrics
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}
